.container-contact{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 100px;
    .contact-description{
        background-color: #F5F5F5;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 90%;
        padding: 10px;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        @include tabletx{
            width: 1000px;
        }
        .titulo2{   
            color: $secundariooscuro;
            margin: 10px;
        }
        .space{
            padding-bottom: 40px;
            .parrafo2{
                color: #8A8A8A;
                font-weight: 100;
                margin: 0px;
            }
        }
    }
    .contact-location{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        justify-content: center;
        margin-top: 50px;
        .titulo2{   
            color: $secundariooscuro;
            margin: 10px;
            width: 100%;
            text-align: center;
            padding-bottom: 20px;
        }
        .location{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-left: 10px;
            padding-right: 10px;
            margin-bottom: 40px;  
            @include movil{
                .br{
                    display: none;
                }
            }
            @include tablet{
                .br{
                    display: block;
                }
            }
            @include tabletx{
                width: 40%;
                padding: 0px;
                margin-right: 50px;
                margin-left: 50px;
            }
            .parrafo2{
                width: 80%;
                color: #8A8A8A;
                font-weight: 100;
                margin: 0px;
                @include tablet{
                    width: 360px;
                }
                @include tabletx{
                    width: 100%;
                }
            }
            .iframe{
                width: 80%;
                height: 250px;
                @include tablet{
                    width: 360px;
                    height: 350px;
                }
                @include tabletx{
                    width: 100%;
                    height: 500px;
                }
            }
        }
    }
}
