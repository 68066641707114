.container-service{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 0px;
    .service-box{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        margin-bottom: 40px;
        @include tabletx{
            margin-bottom: 80px;
        }
        .service-description{
            padding: 10px;
            width: 90%;
            @include tablet{
                width: 80%;
            }
            @include laptop{
                width: 65%;
            }
            .titulo2{
                color: $secundariooscuro;
                margin: 0px;
            }
            .parrafo2{
                font-weight: 100;
                margin: 0px;
                padding-top: 10px;
                color: #919191;
            }
        }
        .service-img{
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            img{
                width: 90%;
                height: 150px;
                @include tablet{
                    width: 80%;
                }
                @include tabletx{
                    width: 65%;
                    height: 300px;
                }
                @include laptop{
                    width: 75%;
                }
            }
        }
    }
}