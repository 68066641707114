html{
	box-sizing: border-box;
	font-size: 62.5%;
}

*, *::before, *::after{
	box-sizing: inherit;
}

body{
	font-family: $fuentePrincipal;
	position: relative;
	&.fijar-body{
		overflow: hidden;
	}
}

p{
	font-size: 2rem;
	color: inherit;
	line-height: 1.5;
}

.contenedor{
	max-width: 120rem;
	width: 95%;
	margin: 0 auto;
}


img{
	//max-width: 100%;
	object-fit: cover;
}

h1, h2, h3, h4{
	margin: 0;
}

h1{
	font-size: 4rem;
}

h2{
	font-size: 3.6rem;
}

h3{
	font-size: 3rem;
}

h4{
	font-size: 2.6rem;
}