$fuentePrincipal: 'Montserrat', sans-serif;

// Colores
$principal: #009DD1;
$principaloscuro: #2A4D9C;
$secundario: #F8AB12;
$secundariooscuro: #F08218;
$blanco:  #FFFFFF;
$negro:   #000000;
$colorface: #3B5998;
$colormail: #144463;
$colorrojo: crimson;



// Media Queries
$movil: 480px;
$tablet: 768px;
$tabletx: 1024px;
$laptop: 1280px;
$macbook: 1440px; 