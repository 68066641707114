.footer{
  background-color: $principaloscuro;

}
.footer-principal{
  display: flex;
  flex-direction: column;
  color: $blanco;
  padding: 2rem;
  justify-content: space-around;
  @include tablet{
    width: 80%;
    margin: 0 auto;
    flex-direction: row;
  }
  .footer-columna{
    width: 100%;
    ul{
      list-style: none;
      padding: 0;
      margin: 0;
      li{
        padding: 1rem 0;
        font-size: 1.6rem;
        a{
          text-decoration: none;
          color: $blanco;
          &:visited,&:link{
            color: $blanco;
          }
        }
      }
    }
    .footer-redes{ 
      display: flex;
      justify-content: center;
      i{padding: 0 1.5rem;}
      a{
        font-size: 3rem;
        text-decoration: none;
        color: $blanco;

      }
    }
    p{
      margin:0 0 2rem 0;
      font-size: 1.8rem;
      text-align: center;
    }
  }
}

.footer-copy{
  color: $blanco;
  padding: 2rem 0;
  p{
    margin: 0;
    font-size: 1.2rem;
    padding: 1rem 0;
    text-align: center;
  }
}