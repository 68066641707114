 .titulo1{
    font-size: 3.6rem;
    font-weight: 400;
    text-transform: uppercase;
    @include tablet{ font-size: 4.4rem;}
    @include tabletx{ font-size: 5rem;}
    @include laptop{ font-size: 5.5rem;}
    @include macbook{ font-size: 6rem;}
  }
  
  .titulo2{
    font-size: 2.6rem;
    font-weight: 700;
    @include tablet{ font-size: 2.8rem;}
    @include tabletx{ font-size: 3.2rem;}
    @include laptop{ font-size: 3.4rem;}
    @include macbook{ font-size: 3.8rem;}
  }
  
  .titulo3{
    font-size: 2.2rem;
    font-weight: 500;
    @include tablet{ font-size: 2.4rem;}
    @include tabletx{ font-size: 2.6rem;}
    @include laptop{ font-size: 2.6rem;}
    @include macbook{ font-size: 2.8rem;}
  }
  
  .titulo4{
    font-size: 1.8rem;
    font-weight: 400;
    @include tablet{ font-size: 1.8rem;}
    @include tabletx{ font-size: 1.8rem;}
    @include laptop{ font-size: 1.8rem;}
    @include macbook{ font-size: 1.8rem;}
  }
  
  .parrafo1{
    font-size: 1.3rem;
    @include tablet{ font-size: 1.4rem;}
    @include tabletx{ font-size: 1.5rem;}
    @include laptop{ font-size: 1.5rem;}
    @include macbook{ font-size: 1.6rem;}
  }
  
  .parrafo2{
    font-size: 1.6rem;
    font-weight: 700;
    @include tablet{ font-size: 1.6rem;}
    @include tabletx{ font-size: 1.8rem;}
    @include laptop{ font-size: 1.8rem;}
    @include macbook{ font-size: 2rem;}
  }
  
  .parrafo3{
    font-size: 1.6rem;
    @include tablet{ font-size: 1.8rem;}
    @include tabletx{ font-size: 2rem;}
    @include laptop{ font-size: 2.2rem;}
    @include macbook{ font-size: 2.4rem;}
  }
  
  .parrafo4{
    font-size: 2.2rem;
    @include tablet{ font-size: 2.8rem;}
    @include tabletx{ font-size: 3.2rem;}
    @include laptop{ font-size: 3.4rem;}
    @include macbook{ font-size: 3.6rem;}
  }
  
.contenedor{
  width: 90%;
  margin: 0 auto;
  @include tabletx{
    width: 80%;
  }
}

.btn{
  background-color: $secundario;
  color: $blanco;
  padding: 1.2rem 4rem;
  border-radius: 20% / 50%;
  font-size: 1.8rem;
  font-weight: bold;
  text-decoration: none;
}
  

  

  