.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom: 15px;
    right: 16px;
	background-color:#4cc370;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}
.float:hover {
	text-decoration: none;
	color: #fff;
  background-color:#3d9e5a;
}

.my-float{
	margin-top:16px;
}