.container-baner{
    width: 100%;
    img{
        height: 300px;
        width: 100%;
        @include tabletx{
            height: 300px;
        }
    }
}

.container-about{
    width: 100%;
    display: flex;
    flex-direction: column;
    .about-description{
        display: flex;
        flex-direction: column;
        padding: 20px;
        padding-top: 40px;
        padding-bottom: 50px;
        align-items: center;
        @include tabletx{
            padding-top: 70px;
            padding-bottom: 70px;
        }
        .titulo1{
            color: $secundariooscuro;
            font-weight: bolder;
            margin: 0px;
            @include tabletx{
                width: 88%;
            }
        }
        .parrafo2{
            font-weight: 100;
            text-align: justify;
            color: #919191;
            margin: 0px;
            padding-top: 5px;
            @include tabletx{
                width: 88%;
            }
        }
    }
    .about-assignment{
        background-color: #003AD8;
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        @include tablet{
            justify-content: center;
            .mision{
                width: 40%;
                padding-right: 20px;
            }
            .vision{
                padding-left: 20px;
                width: 40%;
            }
        }
        @include tabletx{
            justify-content: center;
            padding-top: 50px;
            padding-bottom: 40px;
            .mision{
                padding-right: 30px;
                padding-left: 30px;
                width: 45%;
            }
            .vision{
                padding-left: 30px;
                width: 48%;
            }
        }
        .titulo2{
            color: $secundariooscuro;
            margin: 0px;
        }
        .parrafo2{
            font-weight: 100;
            color: white;
        }
        .img{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 30px;
            img{
                width: 250px;
                @include tablet{
                    width: 400px;
                }
            }
        }
    }
}