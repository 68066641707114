
.carrusel__overlay{
	position: absolute;
	top: 0;
	z-index: 100;
	width: 100%;
	height: 100%;
	background: rgb(42,77,156);
	background: -moz-linear-gradient(60deg, rgba(42,77,156,0.35) 0%, rgba(26,24,190,0.35) 100%);
	background: -webkit-linear-gradient(60deg, rgba(42,77,156,0.35) 0%, rgba(26,24,190,0.35) 100%);
	background: linear-gradient(60deg, rgba(42,77,156,0.35) 0%, rgba(26,24,190,0.35) 100%);
}

.display-1{
	color: $blanco;
}
.carrusel__contenido{
	display: flex;
  	flex-direction: column;
  	justify-content: center;
	align-items:flex-start;
	height: 100%;
	position: relative;
	z-index: 110;
	h2{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		color: $blanco;
		font-size: 7rem;
        font-weight:normal;
	}
  p{
    color: $blanco;
    font-size: 3.5rem;
    margin: 1rem 0 4rem 0;
	@include tablet{
		font-size: 4.5;
	}
  }

}
