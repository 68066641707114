.nada{
  height: 6rem;
  @include tabletx{
    height: 8rem;
  }
}

nav{
  width: 100vw;
  &.navbar{
    padding: 0;
    height: 6rem;
    z-index: 1001;
    background-color: $principaloscuro;
    color: $blanco;

    @include tabletx{
      height: 8rem;
    }
    .navbar-brand{
      text-decoration: none;
      color: $principal;
      font-size: 2.4rem;
      .navbar-brand-info{
        display: flex;
        flex-direction: row;
        color: $blanco;
        text-decoration: none;
        align-items: center;
        gap: 1rem;
      }
      img{
        background-color: $principaloscuro;
        width: 7rem;
        height: 5rem;
        @include tabletx{
          width: 9rem;
          height: 7rem;
        }
      }
    }
    button{
      color: $blanco;
      font-size: 2rem;
    }
    &.fixed-top{
      
      @include tabletx{
        .navbar-nav{
          background-color: $principaloscuro;
        }
      }
    }
    .nav-item{
      padding: 0 .5rem;
      @include tabletx{
        padding: 0 1rem;
      }
    }
    .nav-link{
      text-decoration: none;
      color: $blanco;
      font-size: 1.8rem;
      padding: 1rem;
      &p{
        display: inline-block;
        margin: 0;
      }
    }
    .navbar-collapse{
      width: 100%;
      justify-content: right;
      z-index: 1001;
      background-color: inherit;
    }
    h2{margin: 0;}
    .h2{
      font-size: 2.5rem;
      @include tablet{
        font-size: 3.2rem;
      }      
    }
  }
}

.knobs{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.boton {
  position: relative;
  margin: 1rem;
  padding: 0;;
  display: block;
  width: 6.1rem;
  height: 2.6rem;
  overflow: hidden;
  border: 1px solid $blanco;
  background-color: white;
}

.boton.b2 {
  border-radius: .2rem;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}



/* Button 13 */
#button-13 .knobs:before,
#button-13 .knobs:after,
#button-13 .knobs span {
  position: absolute;
  top: -1px;
  width: 2.8rem;
  height: 1.5rem;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 5px;
  border-radius: 4px;
  transition: 0.3s ease all;
}

#button-13 .knobs:before,
#button-13 .knobs:after {
  color: #4e4e4e;
  z-index: 1;
}

#button-13 .knobs:before {
  content: "ES";
  top: 1px;
  left: 0px;
}

#button-13 .knobs:after {
  top: 1px;
  content: "EN";
  right: 0px;
}

#button-13 .knobs span {
  width: 3.1rem;
  height: 3rem;
  left: 3rem;
  background-color: $principaloscuro;
  z-index: 2;
}

#button-13 .checkbox:checked + .knobs span {
  left: 0px;
  background-color: $principaloscuro;
  top: 0px;
}

.card-text{
  min-height:  22rem;
  line-height: 2;
}
.card-title{
  min-height: 8rem;
}

.card{
 border-radius: .5rem;
 border: 0px solid $colorface;
 padding: 1rem;
 z-index: 1;
box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
}

.carousel-item.active{
  z-index: 0;
}
