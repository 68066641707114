@import 'base/normalize';
@import 'base/bootstrap';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;700;900&display=swap');
@import 'fontawasome/fontawesome.scss';
@import 'fontawasome/solid.scss';
@import 'fontawasome/brands.scss';
@import 'fontawasome/regular.scss';

@import 'base/variables';
@import 'base/mixins';
@import 'base/globales';
@import 'base/utilidades';

@import 'layout/header';
@import 'layout/index-box';
@import 'layout/nosotros';
@import 'layout/servicios';
@import 'layout/contacto';
@import 'layout/carrusel';
@import 'layout/footer';
@import 'layout/tarjetas';
@import 'layout/menuservicios';
@import 'layout/whats';
@import 'layout/whats2';
