.texto-tarjeta{
    font-size: 1.6rem;
    text-align: center;
    padding: 0 2rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    height: 12rem;
}

.iataimg{
    max-height: 12rem;
    object-fit: contain;
}