body{
    margin: 0px;
}

.container-box{
    width: 100%;
    display: flex;
    flex-direction: column;

    .box{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        @include tablet{
            width: 100%;
        }
        @include tabletx{
            margin-bottom: 80px;
        }
        .box-image{
            @include tablet{
                width: 50%;
            }
            img{
                width: 100%;  
            }
        }
        .box-text{
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 10px;
            @include tablet{
                width: 50%;
            }
            @include tabletx{
                padding: 30px;
            }
            @include laptop{
                padding: 40px;
                .normal{
                    padding-right: 80px;
                }
                .invert{
                    padding-left: 80px;
                }
            }
            .titulo1{
                color: $secundariooscuro;
                margin: 0px;
                font-weight: bolder;
            }
            p{
                color: #919191;
                font-weight: 100;
            }
            a{
                color: $principal;
                font-weight: bolder;
            }
        }

    }
}

.container-industry{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 0rem;
        margin-right: 0rem;
    @include tablet{
        margin-top: 100px;
        margin-left: 4rem;
        margin-right: 4rem;
    }
    .industry-title{
        width: 100%;
        .titulo1{
            color: $secundariooscuro;
            margin: 0px;
            font-weight: bolder;
        }
    }
    .industry-box{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        .titulo3{
            color: $principal;
            font-weight: bolder;
            margin: 0px;
        }
        .box-icons{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 150px;
            height: 150px;
            background-color: #F1F1F1;
            cursor: pointer;
            text-decoration: none;
            @include movil{
                width: 200px;
                height: 200px;
            }
            @include tabletx{
                width: 300px;
                height: 300px;
            }
            img{
                width: 80%;
                @include movil{
                    width: 85%;
                }
            }
        }
        .box-icons-1{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 150px;
            height: 150px;
            background-color: #F5F5F5;
            cursor: pointer;
            text-decoration: none;
            @include movil{
                width: 200px;
                height: 200px;
            }
            @include tabletx{
                width: 300px;
                height: 300px;
            }
            img{
                width: 80%;
                @include movil{
                    width: 85%;
                }
            }
        }
    }
}

.container-quotation{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 30px;
    @include laptop{
        margin-top: 80px;
    }
    .quotation-image{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .image-img{
            width: 100%;
            img{
                width: 100%;
                height: 160px;
                @include tablet{
                    height: 260px;
                }
            }
        }
        .image-title{
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            .titulo1{
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0px 5px 9px 1px #BDBDBD;
                width: 270px;
                height: 140px;
                background-color: $secundariooscuro;
                margin: 0px;
                color: white;
                font-weight: bolder;
                line-height: normal;
                margin-top: -75px;
                @include movil{
                    width: 400px;
                }
                @include tablet{
                    width: 700px;
                    height: 130px;
                }
                @include tabletx{
                    width: 850px;
                }
                @include laptop{
                    width: 950px;
                }
            }
        }
    }
    .quotation-contact{
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p{
            color: #919191;
            font-weight: 100;
            @include tablet{
                width: 766px;
            }
            @include tabletx{
                width: 880px;
                margin-top: 40px;
            }
        }
        .quotation-inf{
            text-align: justify;
            .parrafo2{
                color: #919191;
                font-weight: 100;
                @include tabletx{
                    padding-bottom: 15px;
                }
            }
        }
        .titulo2{
            color: $principal;
            font-weight: bolder;
            @include tablet{
                width: 500px;
            }
        }
        form{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .buttonc{
                background-color: #003AD7;
                color: white;
                border: none;
                border-radius: 100px;
                -webkit-border-radius: 100px;
                -moz-border-radius: 100px;
                -ms-border-radius: 100px;
                -o-border-radius: 100px;
                padding: 15px;
                padding-left: 35px;
                padding-right: 35px;
                width: auto;
                margin-top: 0px;
                cursor: pointer;
                transition: all 0.3s ease-out;
                text-decoration: none;
              }
        }
    }
}


  
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 200;
  }
  .overlay:target {
    visibility: visible;
    opacity: 1;
  }
  
  .popup {
    width: 300px;
    margin: 20px;
    padding: 20px;
    background: #fff;
    border-radius: 25px;
    position: relative;
    transition: all 5s ease-in-out;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    @include tablet{
        width: 600px;
        margin: 50px;
        padding: 50px;
    }
}
  
  .popup h3{
    color: $secundariooscuro !important;
    margin: 0px;
    font-weight: bolder;
  }

  .popup p{
      /* border: 1px solid black; */
      height: auto;
      margin: 0px;
      font-weight: bolder !important;
      color: #919191;
      @include tablet{
          margin-top: 10px;
      }
  }

  .popup .close {
    position: absolute;
    top: 5px;
    right: 10px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    -ms-transition: all 200ms;
    -o-transition: all 200ms;
}
  .popup .close:hover {
    color: #06D85F;
  }
  .popup .contentc {
    /* border: 1px solid black; */
    overflow: auto;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include tablet{
        margin-top: 30px;
    }
  }

  .popup .contentc input{
      width: 100%;
      text-align: center;
      border-radius: 25px;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      padding: 10px;
      margin-bottom: 15px;
      color: #919191;
      border: 1px solid #919191;
      @include tablet{
        width: 420px;
      }
      @include laptop{
          width: 480px;
      }
}

.contentc button{
    background-color: #003AD7;
    color: white;
    border: none;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    padding: 10px;
    padding-left: 35px;
    padding-right: 35px;
    width: auto;
    margin-top: 0px;
    cursor: pointer;
    transition: all 0.3s ease-out;
    text-decoration: none;
    width: 120px;
}

.container-anything{
    width: 100%;
    height: 150px;
}


.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}
.float:hover {
	text-decoration: none;
	color: #25d366;
  background-color:#fff;
}

.my-float{
	margin-top:16px;
}