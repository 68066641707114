.menu-servicios{
  position: fixed;
  width: 5rem;
  top:20rem;
  background-color: $blanco;
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  a{
    width: 100%;
  }
  .link-servicio{
    text-decoration: none;
  }
  .servicios-item{
    width: 100%;
    padding: .5rem 0 ;

  }
  .fas{
    padding: 2rem auto;
    width: 5rem;
    font-size: 3rem;
    text-align: center;
    color: $principaloscuro;
  }
  .img-servicio{
    width: 5rem;
  }
  .texto-servicio{
    display: none;
    color: $secundario;
  }

  @include tablet{
    width: 9rem;
    .img-servicio{
      width: 8rem;
      padding: auto;

    }
    .texto-servicio{
      display: block;
      font-size: 1rem;
      color: $secundariooscuro;
      text-decoration: none;
      margin: 0;
      text-align: center;
    }
    .fas{
      text-align: center;
      padding: 2rem auto;
      width: 8rem;
      font-size: 5rem;
    }

  }

}