@import url("https://fonts.googleapis.com/css?family=Roboto");
/* offset-x > | offset-y ^| blur-radius | spread-radius | color */
@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    transform: scale(1.45);
    opacity: 0;
  }
}
.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

.nav-bottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: flex-end;
  width: auto;
  height: auto;
  position: fixed;
  z-index: 150;
  bottom: 0px;
  right: 0px;
  padding: 5px;
  margin: 0px;
}
@media (max-width: 360px) {
  .nav-bottom {
    width: 320px;
  }
}

.whatsapp-button {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 60px;
  height: 60px;
  z-index: 150;
  transition: .3s;
  margin: 10px;
  padding: 7px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  background-color: white;
  /* offset-x > | offset-y ^| blur-radius | spread-radius | color */
  -webkit-box-shadow: 1px 1px 6px 0px rgba(68, 68, 68, 0.705);
  -moz-box-shadow: 1px 1px 6px 0px rgba(68, 68, 68, 0.705);
  box-shadow: 1px 1px 6px 0px rgba(68, 68, 68, 0.705);
}

.circle-anime {
  display: flex;
  position: absolute;
  justify-content: center;
  align-content: center;
  width: 60px;
  height: 60px;
  top: 15px;
  right: 15px;
  border-radius: 50%;
  transition: .3s;
  background-color: #77bb4a;
  animation: pulse 1.2s 4.0s ease 4;
}

.popup-whatsapp {
  display: none;
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: auto;
  height: auto;
  padding: 10px;
  bottom: 85px;
  right: 96px;
  transition: .5s;
  border-radius: 10px;
  background-color: white;
  /* offset-x > | offset-y ^| blur-radius | spread-radius | color */
  -webkit-box-shadow: 2px 1px 6px 0px rgba(68, 68, 68, 0.705);
  -moz-box-shadow: 2px 1px 6px 0px rgba(68, 68, 68, 0.705);
  box-shadow: 2px 1px 6px 0px rgba(68, 68, 68, 0.705);
  animation: slideInRight .6s 0.0s both;
}
.popup-whatsapp > div {
  margin: 5px;
}
@media (max-width: 680px) {
  .popup-whatsapp p {
    font-size: 0.9em;
  }
}
.popup-whatsapp > .content-whatsapp.-top {
  display: flex;
  flex-direction: column;
}
.popup-whatsapp > .content-whatsapp.-top p {
  color: #585858;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 1.0em;
}
.popup-whatsapp > .content-whatsapp.-bottom {
  display: flex;
  flex-direction: row;
}

.cera{
  width: 100% ;
  padding-right: 10px;
  .closePopup {
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    margin: 0px 0px 1px 0px;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #4cc370;
    -webkit-box-shadow: 1px 1px 2px 0px rgba(68, 68, 68, 0.705);
    -moz-box-shadow: 1px 1px 2px 0px rgba(68, 68, 68, 0.705);
    box-shadow: 1px 1px 2px 0px rgba(68, 68, 68, 0.705);
  }
  .closePopup:hover {
    background-color: red;
    transition: .3s;
  }
}



.send-msPopup {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  margin: 0px 0px 0px 5px;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-box-shadow: 1px 1px 2px 0px rgba(68, 68, 68, 0.705);
  -moz-box-shadow: 1px 1px 2px 0px rgba(68, 68, 68, 0.705);
  box-shadow: 1px 1px 2px 0px rgba(68, 68, 68, 0.705);
}
.send-msPopup:hover {
  background-color: #f8f8f8;
  transition: .3s;
}

.is-active-whatsapp-popup {
  display: flex;
  animation: slideInRight .6s 0.0s both;
  background-color: #fafafa;
}

input.whats-input[type=text] {
  width: 250px;
  height: 40px;
  box-sizing: border-box;
  border: 0px solid #ffffff;
  border-radius: 20px;
  font-size: 1em;
  background-color: #ffffff;
  padding: 0px 0px 0px 10px;
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  outline: none;
  transition: .3s;
}
@media (max-width: 420px) {
  input.whats-input[type=text] {
    width: 225px;
  }
}
input.whats-input::placeholder {
  /* Most modern browsers support this now. */
  color: rgba(68, 68, 68, 0.705);
  opacity: 1;
}
input.whats-input[type=text]:focus {
  background-color: #f8f8f8;
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  transition: .3s;
}

.icon-whatsapp-small {
  width: 24px;
  height: 24px;
}

.icon-whatsapp {
  width: 45px;
  height: 45px;
}

.icon-font-color {
  color: #ffffff;
}

.icon-font-color--black {
  color: #333333;
}